import { render, staticRenderFns } from "./profile-webinars.vue?vue&type=template&id=448db7ba&"
import script from "./profile-webinars.vue?vue&type=script&lang=ts&"
export * from "./profile-webinars.vue?vue&type=script&lang=ts&"
import style0 from "./profile-webinars.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCard,VDivider,VFlex,VLayout,VSwitch})


/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/starling-app-staging-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('448db7ba')) {
      api.createRecord('448db7ba', component.options)
    } else {
      api.reload('448db7ba', component.options)
    }
    module.hot.accept("./profile-webinars.vue?vue&type=template&id=448db7ba&", function () {
      api.rerender('448db7ba', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/profile/partials/profile-webinars.vue"
export default component.exports


















import Vue from 'vue';
import ProfileCommunication from '@/views/profile/partials/profile-communication.vue';
import ProfileGlobalSettings from '@/views/profile/partials/profile-global-settings.vue';
import ProfileCommunityDetails from '@/views/profile/partials/profile-community-details.vue';
import ProfileHomeSettings from '@/views/profile/partials/profile-home-settings.vue';

export default Vue.extend({
  name: 'profile-settings',
  components: {
    ProfileCommunityDetails,
    ProfileCommunication,
    ProfileGlobalSettings,
    ProfileHomeSettings,
  },
  data() {
    return {
      loading: {
        details: true,
        community: true,
        global: true,
      },
      refreshKey: 0,
    };
  },
  methods: {
    onLoadingDetails(isLoading: boolean) {
      this.loading.details = isLoading;
      this.checkLoading();
    },
    onLoadingCommunity(isLoading: boolean) {
      this.loading.community = isLoading;
      this.checkLoading();
    },
    onLoadingGlobal(isLoading: boolean) {
      this.loading.global = isLoading;
      this.checkLoading();
    },
    checkLoading() {
      const isSomethingLoading = Object.values(this.loading).some(val => !!val);
      this.$store.commit('setLoadingView', isSomethingLoading);
    },
    refresh() {
      this.refreshKey++;
    },
  },
});

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { key: _vm.refreshKey, attrs: { row: "", wrap: "", "gap-xs-3": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c("profile-community-details", {
            attrs: { loading: _vm.onLoadingCommunity }
          })
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c("profile-home-settings", {
            attrs: { loading: _vm.onLoadingCommunity }
          })
        ],
        1
      ),
      _c("v-flex", { attrs: { xs12: "" } }, [_c("profile-communication")], 1),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c("profile-global-settings", {
            attrs: { loading: _vm.onLoadingGlobal },
            on: { onLocaleChanged: _vm.refresh }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
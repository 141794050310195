import { render, staticRenderFns } from "./profile-home-settings.vue?vue&type=template&id=938644a0&"
import script from "./profile-home-settings.vue?vue&type=script&lang=ts&"
export * from "./profile-home-settings.vue?vue&type=script&lang=ts&"
import style0 from "./profile-home-settings.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCard,VDivider,VFlex,VLayout,VSwitch})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src551672837/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('938644a0')) {
      api.createRecord('938644a0', component.options)
    } else {
      api.reload('938644a0', component.options)
    }
    module.hot.accept("./profile-home-settings.vue?vue&type=template&id=938644a0&", function () {
      api.rerender('938644a0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/profile/partials/profile-home-settings.vue"
export default component.exports
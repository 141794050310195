














import Vue from 'vue';
import ProfileAccountDetails from '@/views/profile/partials/profile-account-details.vue';
import ProfileFeedback from '@/views/profile/partials/profile-feedback.vue';
import ProfileWebinars from '@/views/profile/partials/profile-webinars.vue';

export default Vue.extend({
  name: 'profile-account',
  components: {
    ProfileAccountDetails,
    ProfileFeedback,
    ProfileWebinars,
  },
  data() {
    return {
      loading: {
        details: true,
        community: true,
        global: true,
      },
      refreshKey: 0,
    };
  },
  methods: {
    onLoadingDetails(isLoading: boolean) {
      this.loading.details = isLoading;
      this.checkLoading();
    },
    onLoadingCommunity(isLoading: boolean) {
      this.loading.community = isLoading;
      this.checkLoading();
    },
    onLoadingGlobal(isLoading: boolean) {
      this.loading.global = isLoading;
      this.checkLoading();
    },
    checkLoading() {
      const isSomethingLoading = Object.values(this.loading).some(val => !!val);
      this.$store.commit('setLoadingView', isSomethingLoading);
    },
    refresh() {
      this.refreshKey++;
    },
  },
});
